import React, { useEffect } from "react";
import RootLayout from "../../component/layout";
import ReceiveCrypto from "../../component/crypto/receive";
import ConfirmationCrypto from "../../component/crypto/confirmationCrypto";
import useSocket from "../../redux/hooks/socketHook";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const Crypto = () => {
  useSocket();
  // const [theme, setTheme] = useState("dark");
  const navigate = useNavigate();

  const keyUsed = useSelector(
    (state) => state?.reducer?.checkoutConfig?.keyUsed
  );

  const failureCallbackURL = useSelector(
    (state) => state?.reducer?.checkoutConfig?.failureCallback
  );
  const handleBackClick = () => {
    window.location.assign(failureCallbackURL);
  };
  // const customerId = useSelector((state) => state?.reducer?.checkoutConfig?.customerId?._id) || useSelector((state) => state?.reducer?.customerData?.customerID);

  useEffect(() => {
    if (!keyUsed) {
      navigate("/error/config");
      return;
    }
    // Set attributes on the body tag
    // document.body.setAttribute("data-bs-theme", theme);

    // // Clean up function to remove attributes when the component unmounts
    // return () => {
    //   document.body.setAttribute("data-bs-theme", theme);
    // };
  }, [keyUsed, navigate]);

  return (
    <RootLayout
      title="Receive"
      onBackClick={handleBackClick}
      // onInfoClick={() => window.back()}
    >
      <div className="w-full pb-[110px]">
        <ReceiveCrypto />
        <ConfirmationCrypto />
      </div>
    </RootLayout>
  );
};

export default Crypto;
