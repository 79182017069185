import React from "react";
import { createBrowserRouter } from "react-router-dom";
import Checkout from "../screen/checkout";
import Index from "../screen";
import ConfigError from "../screen/error/config";
import StripeSuccess from "../screen/success/stripe";
import WyrapaySuccess from "../screen/success/wyarpay";
import TripleASuccess from "../screen/success/trplea";
import Timeout from "../screen/error/timeout";
import StripeFailure from "../screen/error/stripe";
import TripleAawaiting from "../screen/awaiting/triplea";
import Signin from "../screen/auth/signin";
import VerifyOtp from "../screen/auth/verifyOtp";
import RegisterKyc from "../screen/kyc/registerKyc";
import KycStatus from "../screen/kyc/status";
import Crypto from "../screen/crypto";
import MudrexKycSuccess from "../screen/success/mudrex/mudrexKyc";
import PaymentMethods from "../screen/success/mudrex/paymentMethods";
import MudrexPaymentStatus from "../screen/awaiting/mudrex";
import SumupSuccess from "../screen/success/sumup";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Index />,
  },
  {
    path: "/checkout",
    element: <Checkout />,
  },
  {
    path: "/error/config",
    element: <ConfigError />,
  },
  {
    path: "error/stripe",
    element: <StripeFailure />,
  },
  {
    path: "/error/timeout",
    element: <Timeout />,
  },
  {
    path: "/success/stripe",
    element: <StripeSuccess />,
  },
  {
    path: "/success/wyrapay",
    element: <WyrapaySuccess />,
  },
  {
    path: "/success/triple-a/:apikey",
    element: <TripleASuccess />,
  },
  {
    path: "/auth/signin",
    element: <Signin />,
  },
  {
    path: "/auth/otp",
    element: <VerifyOtp />,
  },
  {
    path: "/awaiting/triplea",
    element: <TripleAawaiting />,
  },
  {
    path: "/auth/kyc",
    element: <RegisterKyc />,
  },
  {
    path: "/auth/kyc/status",
    element: <KycStatus />,
  },
  {
    path: "/checkout/crypto",
    element: <Crypto />,
  },
  {
    path: "/mudrex/payment/methods",
    element: <PaymentMethods />,
  },
  {
    path: "/success/mudrex/kyc",
    element: <MudrexKycSuccess />,
  },
  {
    path:"/awaiting/mudrex",
    element: <MudrexPaymentStatus />
  },
  {
    path: "/success/sumup/:apikey",
    element: <SumupSuccess />,
  },
]);

export default router;
